body {
  margin: 0px;
  /*
    In order to prevent having to manually set all the texts to 16px,
    we reset the base font size for the body as 16px (1.6rem).
  */
  font-size: 1.6rem;
}

html {
  /* 
    The default font-size is 16px. When we set the
    font size to be 62.5% of that value, we are telling
    the browser to set the root font size to 10 px (16px * 62.5% = 10px).
    This will allow the usage of rem/em without need to convert
    values.
    Example:
      10px -> 1 rem
      32px -> 3.2rem
      64px -> 6.4rem
  */
  font-size: 62.5%;
}

h1,
h2,
h3,
p {
  margin: 0;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

/* Onetrust banner */
#onetrust-banner-sdk {
  font-size: 100% !important;
}

/* Onetrust Privacy Center modal */
#onetrust-pc-sdk {
  font-size: 100% !important;
}